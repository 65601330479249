import React, { memo, useState } from "react";
import logo from "../../assets/images/logoo.png";
import tools from "../../assets/images/tools.png";
import book from "../../assets/images/book.png";
import appstore from "../../assets/images/appstore.png";
import frustrated from "../../assets/images/frust.jpg";
import googleplay from "../../assets/images/googleplay.png";
import "./Home.css";

import { motion, useInView } from "framer-motion";
function Home(props) {
  const AnimateMe = memo(({ children, delay }) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const ref = React.useRef(null);

    // Use Framer Motion's useInView hook to check when element is in view
    const isInView = useInView(ref, { once: true, amount: 0.1 });

    // Trigger animation only if it has not been animated yet
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }

    return (
      <motion.div
        ref={ref}
        initial={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }}
        animate={hasAnimated ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1, delay: delay || 0 }}
        // viewport={{ once: true, amount: 1 }}
      >
        {children}
      </motion.div>
    );
  });
  return (
    <div className="w-full h-[100vh] overflow-scroll bg-white">
      <header className="w-full h-[80px] flex items-center justify-between app-container sticky top-0 bg-white">
        <a href="/" className="cursor-pointer" rel="noreferrer">
          <img src={logo} className="w-[220px] h-[280px]" alt="" />
        </a>

        <button className="border-black border-[1px] px-[20px] h-[50px] text-black">
          ↖ Download
        </button>
      </header>
      <section className="w-full app-container pb-[60px] border-b-[2px] border-black">
        <div className="flex flex-wrap justify-between container mx-auto">
          <div className="w-full md:w-1/2 text-center md:text-left px-[20px] md:px-0 md:pr-[100px] pt-[110px]">
            <AnimateMe>
              <h1 className="text-[40px] font-bold mb-4 leading-tight text-black">
                Get Your Business Finances Back on Track
              </h1>
            </AnimateMe>
            <AnimateMe delay={0.3}>
              <p className="mb-8 font-bold text-black">
                Organize your finances with Vent, your AI pocket accountant.
                Manage invoices, expenses, and more while getting real-time
                insights and financial advice to grow your business.
              </p>
            </AnimateMe>

            <AnimateMe delay={0.5}>
              <div className="container flex flex-col md:flex-row items-center mt-8">
                <a
                  href="https://apps.apple.com/us/app/vent-ai-pocket-accountant/id6563138760"
                  target="_blank"
                  className="cursor-pointer"
                  rel="noreferrer"
                >
                  <img
                    src={appstore}
                    alt="Happy business"
                    className="px-6 py-3 w-[180px] h-[60px] mb-8 md:mb-0"
                  />
                </a>

                <a
                  href="https://play.google.com/apps/testing/com.vent.accountant"
                  target="_blank"
                  className="cursor-pointer"
                  rel="noreferrer"
                >
                  <img
                    src={googleplay}
                    alt="Happy business"
                    className="px-6 py-3 w-[180px] h-[60px]"
                  />
                </a>
              </div>
            </AnimateMe>
          </div>
          <div className="w-full md:w-1/2 px-[20px] md:px-0 md:pr-[100px] pt-[110px]">
            <div className="flex items-center justify-center">
              <AnimateMe delay={0.3}>
                <img
                  src={frustrated}
                  alt="Frustrated"
                  className="h-[260px] w-auto -mr-[5px] relative z-10"
                />
              </AnimateMe>
            </div>
          </div>
        </div>
        {/* <div className="w-full flex justify-end">
          <div className="w-[300px] pt-[60px] pb-4 border-b-[1px] border-black font-bold text-black">
            Organise your business finances with our Pocket Accountant
          </div>
        </div> */}
      </section>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-black">
              What You Get →
            </h3>

            <img src={book} alt="" className="w-[55%] m-auto" />
          </div>
          <div className="w-full md:w-1/2">
            <h4 className="text-[20px] md:text-[34px] leading-6 md:leading-10 mb-[60px] mt-[20px] md:mt-0 text-black">
              Enjoy a future where you can thrive with your very own
              Accountant in your pocket
            </h4>

            <div className="md:pl-[100px]">
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">Invoicing</p>
                <p className="mt-[20px] text-black">
                  Create, send, and track invoices effortlessly. Vent’s smart
                  system helps you stay on top of your revenue streams, ensuring
                  you get paid faster.
                </p>
              </div>
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">Expense Management</p>
                <p className="mt-[20px] text-black">
                  Simplify expense tracking. Log, categorize, and monitor all
                  your business expenses in one place with real-time reporting.
                </p>
              </div>
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">Cash Flow Insights.</p>
                <p className="mt-[20px] text-black">
                  Get a clear view of your financial health. Vent’s AI analyzes
                  your cash flow and provides insights to optimize your
                  financial decisions.
                </p>
              </div>
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">
                  Smart Financial Advice
                </p>
                <p className="mt-[20px] text-black">
                  Unlock actionable advice with Vent’s AI-driven
                  recommendations. Learn where to cut costs, identify growth
                  opportunities, and build a strong financial foundation.
                </p>
              </div>
            </div>
          </div>
        </section>
      </AnimateMe>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-black">
              How It Works →
            </h3>

            <div>
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">
                  Connect Your Accounts
                </p>
                <p className="mt-[20px] text-black">
                  Link your financial accounts in minutes for a holistic view of
                  your finances.
                </p>
              </div>
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">Automate & Analyze</p>
                <p className="mt-[20px] text-black">
                  Vent automatically categorizes transactions, tracks expenses,
                  and manages invoices for you.
                </p>
              </div>
              <div className="border-t-[2px] border-black py-[30px] text-[16px]">
                <p className="font-semibold text-black">
                  Grow with Confidence.
                </p>
                <p className="mt-[20px] text-black">
                  With AI-driven insights and advice, Vent empowers you to make
                  smarter, growth-focused financial decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="md:pl-[100px]">
              <img src={tools} alt="" className="w-[55%] m-auto" />
            </div>
          </div>
        </section>
      </AnimateMe>

      {/* <section className="app-container pt-[30px] pb-[60px] border-b-[2px] border-black mb-[10px]">
        <h1 className="w-full text-[40px] md:text-[80px] alliance-2 font-bold text-black">
          What is Vent?
        </h1>

        <div className="w-full flex flex-wrap py-[30px] px-4 md:px-0">
          <div className="w-full md:w-1/2 px-4 md:px-0 pr-0 md:pr-[120px] mb-4">
            <img src={tailor} alt="" />
          </div>

          <div className="w-full md:w-1/2 px-4 md:px-0">
            <h3 className="text-[20px] md:text-[34px] font-bold mb-[20px] leading-7 md:leading-9 text-black">
              Get Access To Healthy Credit With Your Finances.
            </h3>
            <p className="text-[16px] text-black mb-6">
              You can organize your business finances smoothly with Vent, your
              ultimate pocket accountant. Vent goes beyond just helping you
              create and manage invoices, expenses, customers, and receipts—it’s
              your financial advisor, guiding you toward smarter business
              decisions.
            </p>
            <p className="text-[16px] text-black">
              We are on a mission to help 1,000,000+ businesses like yours
              manage their finances and pave the way to thrive using proper
              credit.
            </p>
          </div>
        </div>
      </section> */}

      <AnimateMe delay={0.3}>
        <section className="app-container py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          {/* <h1
          className="text-[40px] md:text-[100px] font-bold text-black"
          style={{ lineHeight: "110%" }}
        >
          How It Works?
        </h1> */}

          <div className="w-full flex flex-wrap mt-[30px] md:mt-[80px] mb-[90px]">
            <div className="w-full md:w-1/3">
              <p className="text-[30px] font-bold text-black">Benefits</p>
            </div>
            <div className="w-full md:w-2/3">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-x-4">
                <div className="w-full">
                  {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                  <p className="text-black text-[16px] mb-[20px] md:mb-0">
                    Stay Organized – No more scattered records. Keep everything
                    in one place and access it from anywhere.
                  </p>
                </div>
                <div className="w-full">
                  {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                  <p className="text-black text-[16px] mb-[20px] md:mb-0">
                    Get Paid Faster – Automated invoicing and payment reminders
                    mean you never miss a payment again.
                  </p>
                </div>
                <div className="w-full">
                  {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                  <p className="text-black text-[16px] mb-[20px] md:mb-0">
                    Boost Profitability – Track expenses and uncover cost-saving
                    opportunities to maximize profitability.
                  </p>
                </div>
                <div className="w-full">
                  {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                  <p className="text-black text-[16px] mb-[20px] md:mb-0">
                    Informed Decisions – Real-time insights provide clarity on
                    your financial health to help you make data-driven
                    decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimateMe>

      <div
        className="senja-embed display: block;"
        data-id="f4847194-68a7-4279-b0f3-2e822b5c2c45"
        data-mode="shadow"
        data-lazyload="false"
      ></div>

      <AnimateMe delay={0.3}>
        <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-black mb-[10px] px-4 md:px-0">
          <div className="w-full md:w-1/2">
            <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-black">
              Join our community and take control of your business
            </h3>
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-black">
              <p>
                Be part of a transformative journey that is reshaping the
                financial landscape for small businesses. Together, we can
                create a thriving ecosystem where businesses have the tools and
                resources they need to flourish.
              </p>
              {/* <p className="mt-6">
              Today, some of the world’s most important institutions use Foundry
              to build safer cars, secure global supply chains, accelerate
              cancer research, and more.
            </p> */}
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px]">
            {industries.map((industry) => (
              <div className="w-full">
                <div className="flex justify-between text-[20px] border-t-[1px] border-black py-5 text-black">
                  <span>{industry}</span>
                  <span>→</span>
                </div>
              </div>
            ))}
          </div> */}
          </div>
        </section>
      </AnimateMe>
    </div>
  );
}

export default Home;
